import axios from 'axios';
import { ORDER_BASE_URL, SUBSCRIPTION_TEMPLATE_URL } from '../../constants';

export function getOrderTableByCode(code, customerId, pinCode){
    return axios.get(`${ORDER_BASE_URL}/customer/get-table/${code}`, {params: {customerId, pinCode}}).then(res => res.data).catch(error => console.log(error));
}

export function placeOrder(tableId, orderId){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderId}/place-order`, {}, {
        params: {tableId}
    }).then(res => res.data).catch(error => console.log(error));
}

export function requestChecking(tableId){
    return axios.post(`${ORDER_BASE_URL}/customer/${tableId}/request-checking`)
    .then(res => res.data).catch(error => console.log(error));
}

export function makePayment(tableId, customerId){
    return axios.post(`${ORDER_BASE_URL}/customer/${tableId}/customer-making-payment`, {}, {params: {customerId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function cancelPayment(tableId, invoiceId, orderItemIds){
    return axios.post(`${ORDER_BASE_URL}/customer/${tableId}/customer-cancel-payment`, orderItemIds, {params: {invoiceId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function requestPayOnsite(orderNumber, paymentMethod, tipAmount){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/request-pay-onsite`, {}, {params: {paymentMethod, tipAmount}})
    .then(res => res.data).catch(error => console.log(error));
}

export function completeOrderPayment(orderNumber, method, gateway, token, tipAmount){
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/complete`, {}, {
        params: {method, gateway, token, tipAmount}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function callWaiter(tableCode){
    return axios.post(`${ORDER_BASE_URL}/customer/${tableCode}/call-waiter`)
    .then(res => res.data).catch(error => console.log(error));
}  

export function getDineInTableOrder(orderNumber, customerId){
    return axios.get(`${ORDER_BASE_URL}/customer/get-dine-in-order/${orderNumber}`, {
        params: {customerId}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function downloadPdfInvoice(orderNumber, invoiceId, customerId){
    return axios.get(`${ORDER_BASE_URL}/customer/download/pdf-invoice/${orderNumber}`, {
        responseType: "blob",
        params: {invoiceId, customerId}
    })
    .then(res => res.data);
}

export function submitPaymentPlan(tableId, splitValues) {
    return axios.post(`${ORDER_BASE_URL}/customer/${tableId}/split-request`, splitValues)
    .then(res => res.data).catch(error => console.log(error));
}

export function splitPayment(tableId, customerId) {
    return axios.post(`${ORDER_BASE_URL}/customer/${tableId}/customer-splitting-payment`, {}, {params: {customerId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function makePaymentForInvoice(invoiceId, customerId, tableId) {
    return axios.post(`${ORDER_BASE_URL}/customer/${invoiceId}/split-payment/customer-making-payment`, {},{params: {customerId, tableId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function requestPayOnSiteForInvoice(invoiceId, orderNumber, paymentMethod, tipAmount) {
    return axios.post(`${ORDER_BASE_URL}/customer/${invoiceId}/split-payment/request-pay-onsite`, {},{params: {orderNumber, paymentMethod, tipAmount}})
    .then(res => res.data).catch(error => console.log(error));
}

export function cancelPaymentForInvoice(invoiceId, tableId) {
    return axios.post(`${ORDER_BASE_URL}/customer/${invoiceId}/split-payment/customer-cancel-payment`, {},{params: {tableId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function completeOrderPaymentByInvoice(tableId, method, gateway, token, tipAmount, invoiceId){
    return axios.post(`${ORDER_BASE_URL}/customer/${tableId}/split-payment/complete`, {}, {
        params: {method, gateway, token, tipAmount, invoiceId}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function completeOrderPaymentSubscription(amount, token){
    return axios.post(`${SUBSCRIPTION_TEMPLATE_URL}/split-payment/complete`, null, {
        params: {amount, token}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function customerCancelAndReplanSplit(orderNumber, tableId, customerId) {
    return axios.post(`${ORDER_BASE_URL}/customer/${orderNumber}/split-payment/replan`, {}, {params: {tableId, customerId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function customerMakingPrepaymentWhileEating(orderNumber, tableId, customerId, orderItemIds, amount) {
    return axios.post(`${ORDER_BASE_URL}/customer/${tableId}/prepayment/customer-making-payment`, orderItemIds, {params: {orderNumber, customerId, amount}})
    .then(res => res.data).catch(error => console.log(error));
}
