import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import './PricingTable.scss';
import { Panel } from 'primereact/panel';
import moment from "moment";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Fieldset } from "primereact/fieldset";
import { showloading, stoploading } from "../core/service/LoadingService";
import StripeCheckoutForm from "../core/components/payment/StripeCheckoutForm";
import * as notify from "../core/service/NotificationService";
import { CHECKOUT_STATE, TABLE_REQUEST_STATUS, PAYMENT_METHOD, PAYMENT_OPTION, CHANNEL_TYPE, PAYMENT_METHODS, USER_EVENTS, USE_FOR_PURPOSE, ORDER_PAY_MODE, PAYMENT_STATUS, PAYMENT_STATE, ORDER_ITEM_STATUS, VOUCHER_STATUS } from "../constants";
import { getOrderTableByCode, placeOrder, requestChecking, requestPayOnsite, completeOrderPayment, callWaiter, makePayment, cancelPayment, splitPayment, makePaymentForInvoice, completeOrderPaymentSubscription, cancelPaymentForInvoice, requestPayOnSiteForInvoice, customerCancelAndReplanSplit, customerMakingPrepaymentWhileEating } from "../core/service/CustomerOrderService";

class CheckoutOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentOption: PAYMENT_OPTION.Online,
            paymentMethod : PAYMENT_METHOD.Online,
            paymentMode: ORDER_PAY_MODE.post_pay,
        };
    }

    handleCompleteSplitPayment = (isAddTip, isEarlypaymentWhileEating) => {
        showloading();
        console.log(this.state.refToken)
        completeOrderPaymentSubscription(this.state.amount, this.state.refToken)
        .then(res => {
            console.log(res)
            if (!res.errorCode) {
                notify.showSuccessNotify("Payment completed successfully!","Success");
                this.props.history.push(`/pricing-table`);
              } else {
                notify.showErrorNotify(res.errorMessage);
              }
        //   if (res.errorCode){
        //     // notify.showErrorNotify(res.errorMessage);
        //   } else {
        //     this.setState({ 
        //       pickedInvoice: null,
        //       appliedVoucher: null
        //     });
        //   }
        })
        .finally(stoploading());
    }

    handleCardSuccess = (refToken, isAddTip, isEarlypaymentWhileEating, amount) => {
        console.log(refToken)
        if (refToken) {
          this.setState({
            refToken: refToken,
            amount: amount,
            paymentMethod: PAYMENT_METHOD.Online,
          }, () => {
            this.handleCompleteSplitPayment(isAddTip, isEarlypaymentWhileEating)
            // this.state.isPaymentSplited || isEarlypaymentWhileEating ? this.handleCompleteSplitPayment(isAddTip, isEarlypaymentWhileEating) : this.handleCompletePayment(isAddTip);
          });
        }
    }

    cancelPaymentForInvoice = () => {
    
        cancelPaymentForInvoice(this.state.pickedInvoice.id, this.state.table.id)
        .then(res => {
          this.setState({
            pickedInvoice: null
          });
          if (res.errorCode) {
            
          }
        });
    }

    render() {
        let item = this.props.location.state;
        const isEarlypaymentWhileEating = true;
        const { t } = this.props;
        const trans = (path, args = {}) => t(path, args, { framework: "react-i18next" });
        return (
            <div className="p-grid" style={{height: '100%', margin: 20}}>
                <div className="p-col-12  p-md-6">
                    <Panel header={<span style={{fontSize: 18}}>Payment</span>} style={{height: 350}}>
                        <div className="p-grid">
                        <div className="p-col-12">
                            <StripeCheckoutForm
                                trans={trans}
                                onHandleCardSuccess={(e) => this.handleCardSuccess(e.id, false, isEarlypaymentWhileEating, item.price)}
                                onHandleCancel={() => this.props.history.push(`/pricing-table`)}
                                amount={item.price} 
                                currency="USD"
                                stripeKey="pk_test_qGLZB6bSwTAjmSNchy5KAf0v"
                            />
                        </div>
                        </div>
                    </Panel>
                </div>
                <div className="p-col-12  p-md-6">
                    <Panel header={<span style={{fontSize: 18}}>Your Order</span>} style={{height: 350}}>
                        <div className="p-grid" style={{marginTop: 10}}>
                            <div className="p-col-12 p-md-6">
                                <span style={{fontSize: 24, color: '#a0ce4e'}}>{item.head}</span>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <span style={{fontSize: 20}}>${item.price}.00</span>
                            </div>
                        </div>
                        <div className="p-grid" style={{marginTop: 10}}>
                            <div className="p-col-12 p-md-6" style={{display: 'flex', alignItems: 'center'}}>
                                <label style={{fontSize: 20}}>Quantity:</label>
                            </div>
                            <div className="p-col-12 p-md-6" style={{display: 'flex', alignItems: 'center'}}>
                                <label style={{fontSize: 20}}>1 month</label>
                            </div>
                        </div>
                        <div className="p-grid" style={{marginTop: 30}}>
                            <div className="p-col-12 p-md-6" style={{display: 'flex', alignItems: 'center'}}>
                            <label style={{fontSize: 20}}>Subtotal: </label>
                            </div>
                            <div className="p-col-12 p-md-6" style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontSize: 20}}>${item.price}.00</span>
                            </div>
                        </div>
                        <hr style={{height:2, color: 'black'}} />
                        <div className="p-grid" style={{marginTop: 5}}>
                            <div className="p-col-12 p-md-6" style={{display: 'flex', alignItems: 'center'}}>
                            <label style={{fontSize: 20}}>Total: </label>
                            </div>
                            <div className="p-col-12 p-md-6" style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontSize: 20}}>${item.price}.00</span>
                            </div>
                        </div>
                        
                    </Panel>
                </div>
            </div>
        );
    }
}

export default withNamespaces('message')(CheckoutOrder);