import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import './PricingTable.scss';


class PricingTableView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    group : [
                        { 
                            head: 'Online Menu Basic',
                            price: 0,
                            feature: [
                                'Dynamic Value',
                                'Tag Management',
                                'One Language'
                            ]
                        },
                        { 
                            head: 'Online Menu Plus',
                            price: 6,
                            feature: [
                                'Template',
                                'Multi Language',
                                'Web Integration',
                                'Menu Layout Options'
                            ]
                        },
                    ],
                    height: 200
                },
                {
                    group : [
                        { 
                            head: 'Take Away',
                            price: 19,
                            feature: [
                                'Timed Order',
                                'Limit Time',
                                'Order Delivery Integration',
                                'Online Menu - Plus'
                            ]
                        },
                        { 
                            head: 'Dine In',
                            price: 51,
                            feature: [
                                'Table Monitoring',
                                'Table Reservation',
                                'Split Payment',
                                'Call Waiter Functions',
                                'Online Menu - Plus'
                            ]
                        },
                    ],
                    height: 250
                },
                {
                    group: [
                        { 
                            head: 'Dine In + Take Away',
                            price: 65,
                            feature: [
                                'Full Options',
                            ]
                        }
                    ],
                    height: 'auto'
                }
                
                
            ],
        };
    }

    render() {
        return (
            <div >
                <div class="col-md-12 generic_price_table" style={{padding: 20, backgroundColor: 'white'}}>
                {this.state.data.map((item, index) => (
                    <div class="ticket-wrapper">
                        {item.group.map((group) => (
                            <div class="col-md-4 bike_margin_bottom-30">
                                <div class="generic_content clearfix">
                                    <div class="generic_head_price clearfix">
                                        <div class="generic_head_content clearfix">
                                            <div class="head_bg"></div>
                                            <div class="head">
                                                <span>{group.head}</span>
                                            </div>
                                        </div>
                                        <div class="generic_price_tag clearfix">
                                            <span class="price">
                                                {group.price !== 0 ? 
                                                    <>
                                                    <span class="sign">$</span>
                                                    <span class="currency">{group.price}</span>
                                                    <span class="month">/month</span>
                                                    </> : <span class="currency">FREE</span>}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="generic_feature_list" style={{height: item.height}}>
                                        <ul>
                                            {group.feature.map(value => (
                                                <li>{value}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div class="generic_price_btn clearfix">
                                        <a class="btn-add-membership-cart-item"  onClick={() => this.props.history.push({pathname: `/shopping-cart`, state: group})} >Add</a>
                                    </div>
                                </div>
                            </div>
                        ))}                      
                        
                    </div>
                ))}
                
                </div>
                {/* <div className="login-background-image">
                    <img alt="background" src="/assets/fromlabs/Restaurant-background.png" />
                </div> */}

            </div>
        );
    }
}

export default withNamespaces('message')(PricingTableView);