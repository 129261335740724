import Keycloak from 'keycloak-js';
const _kc = new Keycloak('/keycloak.json');

const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'login-required',
    })
        .then(() => {
            localStorage.setItem("isSuperAdmin", 'YES')
            onAuthenticatedCallback();
    })
};

const doLogin = _kc.login

const doLogout = _kc.logout

const getToken = () => _kc.token;

const getParseToken = () => _kc.tokenParsed

const updateToken = (successCallback) => {
    _kc.updateToken(5).then(successCallback).catch(doLogin);
}

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const KcUserService = {
    initKeycloak,
    doLogin,
    doLogout,
    getToken,
    updateToken,
    hasRole,
    getParseToken
}

export default KcUserService