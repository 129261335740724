import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import './PricingTable.scss';
import { Panel } from 'primereact/panel';
import moment from "moment";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Route, Switch, Redirect } from 'react-router-dom';

class ShoppingCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            month: 1,
        };
    }

    getTotal = (count) => {
        return this.state.month * count;
    }

    render() {
        let item = this.props.location.state;
        return (
            <div style={{height: '100%', backgroundColor: 'white'}}>
                <Panel header={"#Order " + moment(new Date()).format('MM-DD-YYYY HH:mm')} style={{margin: 20}} className="p-card">
                    <Card title={<span style={{fontSize: 24, color: '#a0ce4e'}}>{item.head}</span>} 
                        footer={<div style={{justifyContent: 'center', display: 'flex'}}><Button label="CHECKOUT" icon="pi pi-shopping-cart" style={{width: 200}} onClick={() => this.props.history.push({pathname: `/checkout`, state: item})}/></div>}>
                        <div className="p-grid" style={{marginLeft: 20}}>
                            <div className="p-col-12 p-md-3" style={{marginLeft: 20}}>
                                <ul style={{fontSize: 20}}>
                                    {item.feature.map(value => (
                                        <li style={{margin: 5}}>{value}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="p-col-12 p-md-6" style={{marginTop: 20}}>
                                <div className="p-grid" style={{marginLeft: 20}}>
                                    <div className="p-col-12 p-md-2" style={{display: 'flex', alignItems: 'center'}}>
                                        <label style={{fontSize: 24}}>Quantity:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4" style={{display: 'flex', alignItems: 'center'}}>
                                        {/* <input
                                            class="form-control"
                                            value={this.state.month}
                                            onChange={(e) => this.setState({month: e.target.value})}
                                            type="number"
                                            min="1"
                                            max="250"
                                            style={{fontSize: 18}}
                                        /> */}
                                        <label style={{fontSize: 18}}>1 month</label>
                                    </div>
                                </div>
                                <div className="p-grid" style={{marginLeft: 20}}>
                                    <div className="p-col-12 p-md-2" style={{display: 'flex', alignItems: 'center'}}>
                                        <label style={{fontSize: 24}}>Price:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4" style={{display: 'flex', alignItems: 'center'}}>
                                            <label style={{fontSize: 18}}>${item.price}.00</label>
                                    </div>
                                </div>
                                <div className="p-grid" style={{marginLeft: 20}}>
                                    <div className="p-col-12 p-md-2" style={{display: 'flex', alignItems: 'center'}}>
                                        <label style={{fontSize: 24}}>Total:</label>
                                    </div>
                                    <div className="p-col-12 p-md-4" style={{display: 'flex', alignItems: 'center'}}>
                                            <label style={{fontSize: 18}}>${this.getTotal(item.price)}.00</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                
                </Panel>
            </div>
        );
    }
}

export default withNamespaces('message')(ShoppingCart);