// @noflow
/* eslint-disable max-classes-per-file, react/destructuring-assignment */

import React from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements, 
  ElementsConsumer
} from '@stripe/react-stripe';
import { showNotification } from '../../service/NotificationService';
import { showloading, stoploading } from '../../service/LoadingService';
import { moneyFormat } from '../../service/CommonService';
import { Button } from 'primereact/button';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    }
  }

  validationCustomerInput = (cusData) => {
    let errors = {};
    if(!cusData.cusName)
      errors.cusName = {code: 2030};
    if(!cusData.cusPhone)
      errors.cusPhone = {code: 2030};
    
    return errors;
  }

  handleElementChange = (e) => {
    let errors = {...this.state.errors};

    let key = e.elementType;

    if(e.error)
      errors[key] = e.error.message;
    else
      delete errors[key];

    this.setState({errors: errors});
  }

  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if(this.props.customerData){
      const errors = this.validationCustomerInput(this.props.customerData);

      if(Object.keys(errors).length>0){
        this.props.onCustomerDataError(errors);
        return;
      }
    }

    const {stripe, elements} = this.props;

    // If Stripe has not loaded do nothing.
    if (!stripe || !elements) {
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    showloading();
    await stripe.createToken(cardElement)
      .then((payload) => {
        if(payload.token){
          this.props.onHandleCardSuccess(payload.token);
        }else{
          showNotification('error', 'Error Message', payload.error.message);
          stoploading();
        }
    });
  };

  render() {
    const {amount, currency, trans} = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="p-grid p-fluid form-group">
          <div className="p-col-12">
            <label className="p-label p-margin-bottom-5" htmlFor="cardNumber">Card Number</label>
            <CardNumberElement
              id="cardNumber"
              onChange={(e) => this.handleElementChange(e)}
              options={ELEMENT_OPTIONS}
            />
            <span className="p-form-error">{this.state.errors.cardNumber}</span>
          </div>
          <div className="p-col-12">
            <label className="p-label p-margin-bottom-5" htmlFor="expiry">Card Expiration</label>
            <CardExpiryElement
              id="expiry"
              onChange={(e) => this.handleElementChange(e)}
              options={ELEMENT_OPTIONS}
            />
            <span className="p-form-error">{this.state.errors.cardExpiry}</span>
          </div>
          <div className="p-col-12">
            <label className="p-label p-margin-bottom-5" htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              onChange={(e) => this.handleElementChange(e)}
              options={ELEMENT_OPTIONS}
            />
            <span className="p-form-error">{this.state.errors.cardCvc}</span>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col-12">
            <button class="p-button p-component p-button-success p-button-text-icon-left" type="submit">
              <span class="pi-md-payment p-c p-button-icon-left"></span>
                <span class="p-button-text p-c">{trans('res.menu.pay')} ({moneyFormat(amount, currency)})</span>
            </button>
            <button class="p-button p-component p-button-secondary p-button-text-icon-left" type="button" onClick={()=>this.props.onHandleCancel()}>
              <span class="pi-md-close p-c p-button-icon-left"></span>
              <span class="p-button-text p-c">{trans('res.menu.cancel')}</span>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const InjectedCheckoutForm = (props) => {
  const {amount, currency, customerData, trans, onHandleCardSuccess, onHandleCancel, onCustomerDataError} = props;

  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm elements={elements} stripe={stripe} trans={trans} amount={amount} currency={currency} customerData={customerData} onCustomerDataError={(e) => onCustomerDataError(e)}
          onHandleCardSuccess={(e) => onHandleCardSuccess(e)} onHandleCancel={() => onHandleCancel()}
        />
      )}
    </ElementsConsumer>
  );
};

class StripeCheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      amount: this.props.amount,
      currency: this.props.currency,
      customerData: this.props.customerData,
      trans: this.props.trans
    }
  }

  componentDidMount() {
    // // componentDidMount is only called in browser environments,
    // // so this will also work with server side rendering (SSR).

    // // You can inject a script tag manually like this, or you can just
    // // use the 'async' attribute on the Stripe.js v3 script tag.
    
    let stripeJs = document.getElementById('stripev3');

    if(!stripeJs){
      stripeJs = document.createElement('script');
      stripeJs.src = 'https://js.stripe.com/v3/';
      stripeJs.id = 'stripev3';
      stripeJs.async = true;
      // if(this.props.stripeKey){
        stripeJs.onload = () => { 
          // The setTimeout lets us pretend that Stripe.js took a long time to load 
          // Take it out of your production code! 
          setTimeout(() => { 
            this.setState({ 
              stripe: window.Stripe(this.props.stripeKey), 
            }); 
          }, 500); 
        }; 
      // }
      document.body.appendChild(stripeJs);
    }else{
      setTimeout(() => { 
        this.setState({ 
          stripe: window.Stripe(this.props.stripeKey), 
        }); 
      }, 500);
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.amount!==prevProps.amount){
      this.setState({
        amount: this.props.amount
      });
    }
    if(this.props.order!==prevProps.order){
      this.setState({
        order: this.props.order
      });
    }
    if(this.props.customerData!==prevProps.customerData){
      this.setState({
        customerData: this.props.customerData
      });
    }
  }

  render() {
    const {stripe, currency, amount, customerData, trans} = this.state;

    return (
      <Elements stripe={stripe}>
        <InjectedCheckoutForm amount={amount} currency={currency} customerData={customerData} trans={trans} onCustomerDataError={(e) => this.props.onCustomerDataError(e)}
          onHandleCardSuccess={(e) => this.props.onHandleCardSuccess(e)} onHandleCancel={() => this.props.onHandleCancel()}
        />
      </Elements>
    );
  }
}

export default StripeCheckoutForm;
