import axios from 'axios';
import { createBrowserHistory } from 'history';
import { whitelist } from '../security/config';
import { logOut } from '../security/auth';
import { refreshToken } from '../../login/AuthServices';
import { TENANT_ID, USER_ID, USERNAME } from '../../constants';
import { showNotification } from '../service/NotificationService';
import KcUserService from '../../service/KcUserService';

const history = createBrowserHistory();
let isRefreshingToken = false;
let subscribers = [];

function shouldCheck(pathToCheck) {
    const basePath = getBasePath(pathToCheck);
    return !whitelist.some(path => basePath.includes(path))
}

axios.interceptors.request.use((config) => {
    if(shouldCheck(config.url)){
        const now = new Date().getTime() / 1000;
        // if(localStorage.getItem('access_token') !== null && now < localStorage.getItem('tokenExp') && now < localStorage.getItem('refreshExp')){
        if(localStorage.getItem('access_token') !== null){
            // config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
            config.headers.Authorization = `Bearer ${KcUserService.getToken()}`
            // console.log(KcUserService.getParseToken())
        } else {
            KcUserService.doLogin()
            localStorage.setItem('access_token', KcUserService.getToken())
            isRefreshingToken = false;
        }
    }
    // if(TENANT_ID) config.headers.tenantId = TENANT_ID;
    // if(USER_ID) config.headers.userId = USER_ID;
    // if(USERNAME) config.headers.username = USERNAME;
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        let res = error.response;
        if (res) {
            let responseURL = res.request.responseURL;
            switch(res.status) {
                // case 500:
                    // if(responseURL.indexOf('api/programs'))
                    //     showNotification('error', 'Error Message', res.data.errorMessage);
                    // else history.push('/500');
                    // break;
                case 400:
                    return res;
                // case 404:
                //     history.push('/404');
                //     break;
                case 401:
                    // try to refresh token then try again one more time
                    const originalRequest = error.config;
                    if (!isRefreshingToken) {
                        isRefreshingToken = true;
                        return refreshToken().then((res) => {
                            error.config.headers.Authentication = `Bearer ${res.access_token}` // set new token
                            isRefreshingToken = false;
                            onAccessTokenFetched(res.access_token);
                            return axios.request(error.config); // retry request
                        }).catch(err => {
                            if (err.response.status === 401) {
                                // logOut();
                                // isRefreshingToken = false;
                                // history.push('/login');

                                KcUserService.doLogin()
                                localStorage.setItem('access_token', KcUserService.getToken())
                            }
                        });
                    } else {
                        subscribers.push(accessToken => {
                            originalRequest.headers.Authorization = 'Bearer ' + accessToken;
                            axios.request(originalRequest);
                        });
                    }
                    break;
                default:
            }
        }
        return Promise.reject(error);
    }
);

function onAccessTokenFetched(accessToken) {
    subscribers.forEach(callback => callback(accessToken));
    subscribers = [];
}

function getBasePath(url) {
    const regex = /(http[s]?:\/\/)?([^\\/\s]+\/)(.*)/;
    let m = regex.exec(url);
    return m ? m[3] : "";
}