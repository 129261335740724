export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const USER_ID = process.env.REACT_APP_USER_ID;
export const USERNAME = process.env.REACT_APP_USERNAME;

let loginURL = '';
if (process.env.REACT_APP_LOGIN_BASE_URL) {
  loginURL = process.env.REACT_APP_LOGIN_BASE_URL;
} else {
  loginURL = process.env.REACT_APP_API_BASE_URL;
};
export const LOGIN_BASE_URL = loginURL;

let systemcontrolURL = '';
if (process.env.REACT_APP_SYSTEM_CONTROL_URL) {
  systemcontrolURL = process.env.REACT_APP_SYSTEM_CONTROL_URL;
} else {
  systemcontrolURL = process.env.REACT_APP_API_BASE_URL;
};
export const SYSTEM_CONTROL_BASE_URL = systemcontrolURL;

let menuUrl = '';
if (process.env.REACT_APP_MENU_BASE_URL) {
  menuUrl = process.env.REACT_APP_MENU_BASE_URL;
} else {
  menuUrl = process.env.REACT_APP_API_BASE_URL;
};

let pimUrl = '';
if (process.env.REACT_APP_API_PIM_URL) {
  pimUrl = process.env.REACT_APP_API_PIM_URL;
} else {
  pimUrl = process.env.REACT_APP_API_BASE_URL;
};

let formURL = '';
if (process.env.REACT_APP_FORM_BASE_URL) {
  formURL = process.env.REACT_APP_FORM_BASE_URL;
} else {
  formURL = process.env.REACT_APP_API_BASE_URL;
};

let snsURL = '';
if (process.env.REACT_APP_SNS_BASE_URL) {
  snsURL = process.env.REACT_APP_SNS_BASE_URL;
} else {
  snsURL = process.env.REACT_APP_API_BASE_URL;
};

let mailServiceUrl = '';
if (process.env.REACT_APP_MAIL_BASE_URL) {
  mailServiceUrl = process.env.REACT_APP_MAIL_BASE_URL;
} else {
  mailServiceUrl = process.env.REACT_APP_API_BASE_URL;
};

let tableUrl = '';
if (process.env.REACT_APP_TABLE_BASE_URL) {
  tableUrl = process.env.REACT_APP_TABLE_BASE_URL;
} else {
  tableUrl = process.env.REACT_APP_API_BASE_URL;
};

let orderUrl = '';
if (process.env.REACT_APP_ORDER_BASE_URL) {
  orderUrl = process.env.REACT_APP_ORDER_BASE_URL;
} else {
  orderUrl = process.env.REACT_APP_API_BASE_URL;
};

let taxUrl = '';
if (process.env.REACT_APP_TAX_BASE_URL) {
  taxUrl = process.env.REACT_APP_TAX_BASE_URL;
} else {
  taxUrl = process.env.REACT_APP_API_BASE_URL;
};

let tagUrl = '';
if (process.env.REACT_APP_TAG_BASE_URL) {
  tagUrl = process.env.REACT_APP_TAG_BASE_URL;
} else {
  tagUrl = process.env.REACT_APP_API_BASE_URL;
};

let uploadUrl = '';
if (process.env.REACT_APP_UPLOAD_BASE_URL) {
  uploadUrl = process.env.REACT_APP_UPLOAD_BASE_URL;
} else {
  uploadUrl = process.env.REACT_APP_API_BASE_URL;
};

let userUrl = '';
if (process.env.REACT_APP_USER_BASE_URL) {
  userUrl = process.env.REACT_APP_USER_BASE_URL;
} else {
  userUrl = process.env.REACT_APP_API_BASE_URL;
};

let paymentUrl = '';
if (process.env.REACT_APP_PAYMENT_BASE_URL) {
  paymentUrl = process.env.REACT_APP_PAYMENT_BASE_URL;
} else {
  paymentUrl = process.env.REACT_APP_API_BASE_URL;
};

let tableOrderUrl = '';
if (process.env.REACT_APP_TABLE_ORDER_BASE_URL) {
  tableOrderUrl = process.env.REACT_APP_TABLE_ORDER_BASE_URL;
} else {
  tableOrderUrl = process.env.REACT_APP_API_BASE_URL;
};

let reportUrl = '';
if (process.env.REACT_APP_REPORT_BASE_URL) {
  reportUrl = process.env.REACT_APP_REPORT_BASE_URL;
} else {
  reportUrl = process.env.REACT_APP_API_BASE_URL;
};

let invoiceUrl = '';
if (process.env.REACT_APP_INVOICE_BASE_URL) {
  invoiceUrl = process.env.REACT_APP_INVOICE_BASE_URL;
} else {
  invoiceUrl = process.env.REACT_APP_API_BASE_URL;
};

let paymentTransactionUrl = '';
if (process.env.REACT_APP_PAYMENT_TRANSACTION_BASE_URL) {
  paymentTransactionUrl = process.env.REACT_APP_PAYMENT_TRANSACTION_BASE_URL;
} else {
  paymentTransactionUrl = process.env.REACT_APP_API_BASE_URL;
};

let inqueryFormUrl = '';
if (process.env.REACT_APP_INQUERY_FORM_BASE_URL) {
  inqueryFormUrl = process.env.REACT_APP_INQUERY_FORM_BASE_URL;
} else {
  inqueryFormUrl = process.env.REACT_APP_API_BASE_URL;
};

let subscriptionTemplateUrl = '';
if (process.env.REACT_APP_SUBSCRIPTION_TEMPLATE) {
  subscriptionTemplateUrl = process.env.REACT_APP_SUBSCRIPTION_TEMPLATE;
} else {
  subscriptionTemplateUrl = process.env.REACT_APP_API_BASE_URL;
}

let subsriptionServiceUrl = '';
if (process.env.REACT_APP_SUBSCRIPTION_BASE_URL) {
  subsriptionServiceUrl = process.env.REACT_APP_SUBSCRIPTION_BASE_URL;
} else {
  subsriptionServiceUrl = process.env.REACT_APP_API_BASE_URL;
}

///New implementation

let tenantUrl = '';
if(process.env.REACT_APP_API_TENANT_URL) {
  tenantUrl = process.env.REACT_APP_API_TENANT_URL
} else {
  tenantUrl = process.env.REACT_APP_API_BASE_URL
}

let aclUrl = '';
if(process.env.REACT_APP_API_ACL_URL) {
  aclUrl = process.env.REACT_APP_API_ACL_URL
} else {
  aclUrl = process.env.REACT_APP_API_BASE_URL
}

let accountUrl = '';
if(process.env.REACT_APP_API_ACCOUNT_URL) {
  accountUrl = process.env.REACT_APP_API_ACCOUNT_URL
} else {
  accountUrl = process.env.REACT_APP_API_BASE_URL
}

let keycloakUrl = '';
if (process.env.REACT_APP_API_ACCOUNT_URL) {
  keycloakUrl = process.env.REACT_APP_API_KEYCLOAK_URL
} else {
  keycloakUrl = process.env.REACT_APP_API_BASE_URL
}

export const MAIL_BASE_URL = mailServiceUrl;

export const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL;
export const WS_FALLBACK_BASE_URL = process.env.REACT_APP_WS_FALLBACK_BASE_URL;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_CREDENTIALS = process.env.REACT_APP_CLIENT_CREDENTIALS;
export const TENANT = process.env.REACT_APP_TENANT;

export const SCP_PATH = process.env.REACT_APP_PATH_SCP;
export const MENU_PATH = process.env.REACT_APP_PATH_MENU;
export const TABLE_PATH = process.env.REACT_APP_PATH_TABLE;
export const ORDER_PATH = process.env.REACT_APP_PATH_ORDER;
export const PIM_PATH = process.env.REACT_APP_PATH_PIM;
export const MAIL_PATH = process.env.REACT_APP_PATH_MAIL;
export const FORM_PATH = process.env.REACT_APP_PATH_FORM;
export const SNS_PATH = process.env.REACT_APP_PATH_SNS;
export const TAX_PATH = process.env.REACT_APP_PATH_TAX;
export const TAG_PATH = process.env.REACT_APP_PATH_TAG;
export const UPLOAD_PATH = process.env.REACT_APP_PATH_UPLOAD;
export const USER_PATH = process.env.REACT_APP_PATH_USER;
export const PAYMENT_PATH = process.env.REACT_APP_PATH_PAYMENT;
export const TABLE_ORDER_PATH = process.env.REACT_APP_PATH_TABLE_ORDER;
export const INVOICE_PATH = process.env.REACT_APP_PATH_INVOICE;
export const REPORT_PATH = process.env.REACT_APP_PATH_REPORT;
export const PAYMENT_TRANSACTION_PATH = process.env.REACT_APP_PATH_PAYMENT_TRANSACTION;
export const INQUERY_FORM_PATH = process.env.REACT_APP_PATH_INQUERY_FORM;
export const SUBSCRIPTION_TEMPLATE_PATH = process.env.REACT_APP_PATH_SUBSCRIPTION_TEMPLATE;
export const STORE_TEMPLATE_PATH = process.env.REACT_APP_PATH_STORE_TEMPLATE;
export const SUBSCRIPTION_PATH = process.env.REACT_APP_PATH_SUBSCRIPTION;
export const ACL_PATH = process.env.REACT_APP_PATH_ACL
export const TENANT_PATH = process.env.REACT_APP_PATH_TENANT
export const ACCOUNT_PATH = process.env.REACT_APP_PATH_ACCOUNT
export const KEYCLOAK_PATH = process.env.REACT_APP_PATH_KEYCLOAK

export const FORM_BASE_URL = formURL + FORM_PATH;
export const SNS_BASE_URL = snsURL + SNS_PATH;
export const MENU_BASE_URL = menuUrl + MENU_PATH;
export const PIM_BASE_URL = pimUrl + PIM_PATH;
export const TABLE_BASE_URL = tableUrl + TABLE_PATH;
export const ORDER_BASE_URL = orderUrl + ORDER_PATH;
export const TAX_BASE_URL = taxUrl + TAX_PATH;
export const TAG_BASE_URL = tagUrl + TAG_PATH;
export const UPLOAD_BASE_URL = uploadUrl + UPLOAD_PATH;
export const USER_BASE_URL = userUrl + USER_PATH;
export const PAYMENT_BASE_URL = paymentUrl + PAYMENT_PATH;
export const TABLE_ORDER_BASE_URL = tableOrderUrl + TABLE_ORDER_PATH;
export const INVOICE_BASE_URL = invoiceUrl + INVOICE_PATH;
export const REPORT_BASE_URL = reportUrl + REPORT_PATH;
export const INQUERY_FORM_URL = inqueryFormUrl + INQUERY_FORM_PATH;
export const PAYMENT_TRANSACTION_BASE_URL = paymentTransactionUrl + PAYMENT_TRANSACTION_PATH;
export const SUBSCRIPTION_TEMPLATE_URL = subscriptionTemplateUrl + SUBSCRIPTION_TEMPLATE_PATH;
export const STORE_TEMPLATE_URL = subscriptionTemplateUrl + STORE_TEMPLATE_PATH;
export const SUBSCRIPTION_BASE_URL = subsriptionServiceUrl + SUBSCRIPTION_TEMPLATE_PATH;
export const TENANT_BASE_URL = tenantUrl + TENANT_PATH;
export const ACL_BASE_URL = aclUrl + ACL_PATH;
export const ACCOUNT_BASE_URL = accountUrl + ACCOUNT_PATH;
export const KEYCLOAK_BASE_URL = keycloakUrl + KEYCLOAK_PATH;

export const APPLICATION_FORM_VIEW_URL = process.env.REACT_APP_APPLICATION_FORM_VIEW_URL;

let configServices = '';
if(process.env.REACT_APP_CONFIGURATION_BASE_URL){
  configServices = process.env.REACT_APP_CONFIGURATION_BASE_URL;
} else {
  configServices = process.env.REACT_APP_API_BASE_URL;
}
export const CONFIGURATION_BASE_URL = configServices;

export function getTenantAlias() {
  let parts = window.location.host.split(".");
  let subdomain = "ymca";
  // If we get more than 3 parts, then we have a subdomain
  // INFO: This could be 4, if you have a co.uk TLD or something like that.
  if (parts.length >= 3) {
      subdomain = parts[0];
  }
  return subdomain.split("-")[0];
};

export const MESSAGE = {
  ERROR_FIELD_INPUT_REQUIRED: "Field require input",
  ERROR_FIELD_SELECT_REQUIRED: "Please select a value",
  INVALID_NUMBER_FORMAT: "Wrong number",
  INVALID_EMAIL_FORMAT: "Wrong email format",
  DUPLICATED_VALUE: "This value has existed"
};

export const TABLE_PAGING_LENGTH = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];

export const NAME_TITLES = [
  { label: "Not use", value: "" },
  { label: "Mr.", value: "Mr." },
  { label: "Ms.", value: "Ms." },
  { label: "Dr.", value: "Dr." }
];

export const GENDERS = [
  { label: "Any", value: "Any" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non-binary", value: "NonBinary" }
];

export const FIELD_TYPE = {
  text: "text",
  number: "number",
  email: "email",
  phone: "phone",
  select: "select",
  radio: "radio",
  checkbox: "checkbox",
  boolean: "yes_no",
  textarea: "textarea",
  file: "file",
  date: "date",
  waiver: "waiver"
};

export const FIELD_EXPRESSION_TYPE = {
  none: 0,
  pattern: 1,
  maskString: 2
};

export const FIELD_DISPLAY_TYPE = {
  dropdown: "dropdown",
  checkbox: "checkbox",
  radio: "radio",
  textbox: "textbox",
  file: "file"
};

export const ENCRYPTED_OPTIONS = [
  { label: "UNENCRYPTED", value: "Unencrypted" },
  { label: "SSL/TLS", value: "SSLTLS" },
  { label: "STARTTLS", value: "STARTTLS" }
];

export const PAYMENT_GATEWAY = {
  OFFLINE: "OFFLINE",
  STRIPE: "STRIPE",
  SQUARE: "SQUARE",
  PAYPAL: "PAYPAL"
  // BRAINTREE: "BRAINTREE",
  // SLIM_CD: "SLIM_CD",
  // LUMINATE_DONATION_API: "LUMINATE_DONATION_API",
  // RED_DOT: "RED_DOT",
  // FREE: "FREE",
  // USER_CREDIT: "USER_CREDIT"
};

export const PAYMENT_OPTION = {
  Onsite: 'Onsite',
  Online: 'Online'
}

export const ORDER_PAY_MODE = {
  pre_pay: 'pre_pay',
  post_pay: 'post_pay'
}

export const PAYMENT_METHOD = {
  Cash: "Cash",
  SwipeCard: "SwipeCard",
  CreditCard: "CreditCard"
};

export const PAYMENT_METHODS = [
  {value: 'Cash', label: 'res.menu.pay_cash'},
  {value: 'SwipeCard', label: 'res.menu.pay_card'}
];

export const ORDER_STATE = {
  cart: 'cart',
  new_order: 'new_order',
  viewed: 'viewed',
  canceled: 'canceled'
};

export const PAYMENT_STATE = {
  pending: "pending",
  awaiting: "awaiting",
  awaiting_complete: "awaiting_complete",
  completed: "completed"
};

export const PAYMENT_STATES = [
  {value: "pending", label: 'res.mgt.pending'},
  {value: "awaiting", label: 'res.mgt.awaiting'},
  {value: "awaiting_complete", label: 'res.mgt.awating_complete'},
  {value: "completed", label: 'res.mgt.completed'}
];

export const  CHECKOUT_STATE = {
  cart: 'cart',
  waiting_participant: 'waiting_participant',
  waiting_address: 'waiting_address',
  waiting_checkout: 'waiting_checkout',
  completed: 'completed'
};

export const SHIPPING_STATE = {
  pending: 'pending',
  preparing: 'preparing',
  ready: 'ready',
  shipping: 'shipping',
  shipped: 'shipped',
  completed: 'completed'
}

export const CHANNEL_TYPE = {
  store: "store",
  restaurant: "restaurant",
  tip: "tip",
  restaurant_2: "restaurant_2"
};

export const PRODUCT_TYPE = {
  personalTicket: "personal_ticket",
  groupPersonalTicket: "group_personal_ticket",
  normalTicket: "normal_ticket",
  groupNormalTicket: "group_normal_ticket",
  reservation: "reservation",
  session: "session",
  allocation: "allocation",
  addon: "addon",
  merchandize: "merchandize",
  membership: "membership",
  donation: "donation",
  dish: "dish"
};

export const ORDER_ITEM_STATUS = {
  pending: 'pending',
  waiting: 'waiting',
  processing: 'processing',
  completed: 'completed',
  canceled: 'canceled'
}

export const NOTE_STATUSES = [
  { label: 'New', value: 'create' },
  { label: 'In Progress', value: 'inprogress' },
  { label: 'Done', value: 'done' },
  { label: 'Reopen', value: 'reopen' },
];

export const TABLE_STATUS = {
  available: "available",
  occupied: "occupied"
}

export const TAXON_TYPE = {
  category: 'category',
  menu: 'menu'
};

export const FORMLOCK_TYPE = {
  table: 'table',
  menu: 'menu'
};

export const GetLabelFromListSimple = (list, value) => {
  let lable = '';
  let item = list.find(item => item.value === value);
  if(item){
    lable = item.label;
  }
  return lable;
};

export const RANGE_BIRTHDAY = (new Date().getFullYear() - 100) + ':' + (new Date().getFullYear() + 5)

export const TABLE_STATUSES = [
  {value: 'available', label: 'Available'},
  {value: 'occupied', label: 'Occupied'}
]

export const TABLE_REQUEST_STATUS = {
  confirm_request: 'confirm_request',
  check_request: 'check_request',
  waiting_payment: 'waiting_payment',
  making_payment: 'making_payment',
  pay_by_cash_request: 'pay_by_cash_request',
  pay_by_swipe_card_request: 'pay_by_swipe_card_request',
  complete_payment: 'complete_payment',
  order_confirmed: 'order_confirmed',
  cleaned: 'cleaned'
}

export const TABLE_REQUEST_STATUSES = [
  {value: 'confirm_request', label: 'Confirm order request'},
  {value: 'check_request', label: 'Check request'},
  {value: 'waiting_payment', label: 'Waiting payment'},
  {value: 'pay_by_cash_request', label: 'Pay by cash request'},
  {value: 'pay_by_swipe_card_request', label: 'Pay by swipe card request'},
  {value: 'complete_payment', label: "Payment completed"}
]

export const TAG_TYPE = {
  foodIngredient: 'foodIngredient',
  foodAllergen: 'foodAllergen'
};

export const TAG_TYPES = [
  {value: 'foodIngredient', label: 'Food Ingredient'},
  {value: 'foodAllergen', label: 'Food Allergen'}
];

export const USER_EVENTS = {
  message: 'message',
  newCustomer: 'newCustomer',
  tableCustomer: 'tableCustomer',
  tableRequestStatusChanged: 'tableRequestStatusChanged',
  waiterRequested: 'waiterRequested',
  waiterResponded: 'waiterResponded',
  orderPlaced: 'orderPlaced',
  orderUpdated: 'orderUpdated',
  processPayment: 'processPayment',
  itemAdded: 'itemAdded',
  itemUpdated: 'itemUpdated',
  itemRemoved: 'itemRemoved',
  takeAwayOrderPlaced: 'takeAwayOrderPlaced',
  takeAwayOrderPaymentStatusChange: 'takeAwayOrderPaymentStatusChange',
  takeAwayOrderShippingStatusChange: 'takeAwayOrderShippingStatusChange',
  tableOrderStatusChange: 'tableOrderStatusChange'
}

export const ADJUSTMENT_TYPE = {
  add_item: 'add_item',
  order: 'order'
}

export const ADJUSTMENT_TYPES = [
  {value: 'add_item', label: 'res.mgt.adjust_add_item'},
  // {value: 'order', label: 'res.mgt.adjust_order'},
]

export const PRODUCT_OPTION_TYPE = {
  variant: 'variant',
  properties: 'properties'
}

export const PRODUCT_OPTION_TYPES = [
  {value: 'variant', label: 'res.mgt.option_variant'},
  {value: 'properties', label: 'res.mgt.option_properties'},
]

export const PRODUCT_VARIANT_TYPE = {
  simple: 'simple',
  configuration: 'configuration'
}

export const USE_FOR_PURPOSE = {
  res_dine_in: 'res_dine_in',
  res_take_out: 'res_take_out'
}

export const USE_FOR_PURPOSES = [
  {value: 'res_dine_in', label: 'res.mgt.dine_in'},
  {value: 'res_take_out', label: 'res.mgt.take_out'},
]

export const PAYMENT_GATEWAYS = [
  // {label: 'OFFLINE', value: "OFFLINE"},
  {label: 'SQUARE', value: "SQUARE"},
  {label: 'STRIPE', value: "STRIPE"},
  {label: 'PAYPAL', value: "PAYPAL"}
  // {label: 'BRAINTREE', value: "BRAINTREE"},
  // {label: 'SLIM_CD', value: "SLIM_CD"},
  // {label: 'LUMINATE_DONATION_API', value: "LUMINATE_DONATION_API"},
  // {label: 'RED_DOT', value: "RED_DOT"},
  // {label: 'FREE', value: "FREE"},
  // {label: 'USER_CREDIT', value: "USER_CREDIT"}
]

export const TABLE_TYPE = {
  eating_in: 'eating_in',
  take_away: 'take_away'
}

export const TABLE_TYPES = [
  {value: 'eating_in', label: 'res.mgt.eating_in'},
  {value: 'take_away', label: 'res.mgt.take_away'},
]

export const TABLE_ORDER_STATUS = {
  open: 'open',
  closed: 'closed'
}

export const TABLE_ORDER_STATUSES = [
  {value: 'open', label: 'res.mgt.table_status_open'},
  {value: 'closed', label: 'res.mgt.table_status_closed'},
  {value: 'canceled', label: 'res.mgt.table_status_canceled'}
]

export function translateListSimple(trans, list){
  for(var i=0; i<list.length; i++){
    list[i].label = trans(list[i].label);
  }
  return list;
}

export function translateItemListSimple(trans, list, value){
  let item = list.find(i => i.value===value);
  if(item && item !== -1) return trans(item.label);
  return value;
}

export const REPORT_TYPE = {
  order: 'order',
  // transaction: 'transaction',
  invoice: 'invoice'
}

export const REPORT_TYPES = [
  {value: 'order', label: 'res.mgt.order'},
  // {value: 'transaction', label: 'res.mgt.transaction'}
  {value: 'invoice', label: 'res.mgt.invoice'}
]