import React from 'react';
import { formatMoney } from "accounting-js";
import { CHANNEL_TYPE, ADJUSTMENT_TYPE, PAYMENT_STATE, PAYMENT_METHOD, SHIPPING_STATE } from "../../constants";

export function replaceSpaceAndUpperCase(data){
    let str = data.toUpperCase().replace(/ /g,"_");
    return str
}

export function titleCaseText(data){
    let str;
    if(data) str = data.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
    return str;
}

export function upperCaseField(data){
    let str = data && data.toUpperCase();
    return str
}

export function TableStatusLine(page, rows, total){
    let first = page * rows;
    let last = page * rows + rows;
    if (last > total) last = total;
    return `Showing ${first} to ${last} of ${total} entries`;
};

export function moneyFormat(amount, symbol){
    return formatMoney(amount, {symbol: symbol?symbol:'$', precision: 2, thousand: ',', decimal: '.'})
}

export function getGroupOrderItems(orders, categories){
    let mapGroupItems = {};
    let hasNoneCategory = false;
    let adjustItems = [];

    orders.map(o => {
        if(o.channel===CHANNEL_TYPE.restaurant || o.channel===CHANNEL_TYPE.restaurant_2){
            o.items.map(item => {
                if(item.orderType===ADJUSTMENT_TYPE.order){
                    const cate = item.refObject && item.refObject.taxonId ? item.refObject.taxonId : 'none';
                    const prodId = item.productId;

                    let items = mapGroupItems[cate] && mapGroupItems[cate][prodId]?[...mapGroupItems[cate][prodId].items]:[];

                    let itemIdx = items.length>0?items.findIndex(i => i.taxonId===item.refObject.taxonId && i.productId===item.productId && i.variant.id===item.variant.id && i.note==item.note):-1;
                        
                    if(itemIdx!==-1){
                        items[itemIdx].quantity += item.quantity;
                        items[itemIdx].taxPrice += item.taxPrice;
                        items[itemIdx].total += item.total;
                        items[itemIdx].orderItems.push(item);
                    }else{
                        let tmpItem = {
                            productId: item.productId,
                            taxonId: item.refObject.taxonId,
                            variant: item.variant,
                            note: item.note,
                            itemOptionValueTrans: item.itemOptionValueTrans,
                            quantity: item.quantity,
                            taxPrice: item.taxPrice,
                            total: item.total,
                            orderItems: [item]
                        };

                        items.push(tmpItem);
                    }

                    mapGroupItems = {
                        ...mapGroupItems, 
                        [cate]: {
                            ...mapGroupItems[cate],
                            [prodId]: {
                                name: item.productName,
                                names: item.productNames,
                                items: items
                            }
                        }
                    }

                    if(cate==='none')
                        hasNoneCategory=true;
                }else{
                    adjustItems.push(item);
                }
            })
        }
    });

    let mapResult = [];

    if(Object.entries(mapGroupItems).length>0){
        categories.map(c => {
            if(mapGroupItems[c.id]){
                mapResult.push({
                    cateNames: c.trans.names,
                    products: Object.keys(mapGroupItems[c.id]).map(k => (mapGroupItems[c.id][k]))
                });
            }
        });

        if(hasNoneCategory || adjustItems.length>0){
            mapResult.push({
                cateNames: '',
                products: mapGroupItems.none?Object.keys(mapGroupItems.none).map(k => (mapGroupItems.none[k])):[],
                adjustItems: adjustItems
            });
        }
    }
    
    return mapResult;
}

export function getTableOrderSummary(orders, isPriceIncludedTax){
    let qtyTotal = 0;
    let subTotalNoTax = 0;
    let subTotalWithTax = 0;
    let taxTotal = 0;
    // let orderTotal = 0;
    let tipTotal = 0;
    let taxs = {};

    orders.map(order => {
        order.items.map(item => {
            qtyTotal += item.quantity;

            if(isPriceIncludedTax){
                subTotalWithTax += item.total;
                subTotalNoTax += (item.total - item.taxPrice);
            }else{
                subTotalNoTax += item.total;
                subTotalWithTax += (item.total + item.taxPrice);
            }
            
            taxTotal += item.taxPrice;

            if(item.taxCateId){
                if(!taxs[item.taxCateId]){
                    taxs = {
                        ...taxs,
                        [item.taxCateId]: {
                            name: item.taxCate.name,
                            rate: item.taxRate,
                            total: item.taxPrice
                        }
                    }
                }else{
                    taxs = {
                        ...taxs,
                        [item.taxCateId]: {
                            ...taxs[item.taxCateId],
                            total: taxs[item.taxCate.id].total + item.taxPrice
                        }
                    }
                }
            }
        });

        // orderTotal += subTotalWithTax;

        if(order.channel===CHANNEL_TYPE.tip){
            tipTotal += order.total;
        }
    });

    return {
        qtyTotal: qtyTotal,
        subTotalNoTax: subTotalNoTax,
        subTotalWithTax: subTotalWithTax,
        taxTotal: taxTotal,
        tipTotal: tipTotal,
        orderTotal: subTotalWithTax + tipTotal,
        taxs: taxs
    };
}

export function renderOrderMgtItemView(orderData, currency, isNewOrder, hasNewOrder, trans, defaultLocale, selectedLocale){
    return (
        <React.Fragment>
            {(isNewOrder || hasNewOrder) &&
            <React.Fragment>
                {isNewOrder?
                <h2 className="p-margin-bottom-5">
                    {trans('res.mgt.new_order')}
                </h2>
                :
                <React.Fragment>
                {hasNewOrder && <div className="p-clearfix p-margin-top-10"></div>}
                <h2 className="p-margin-bottom-5 p-margin-top-30">
                    {trans('res.mgt.in_service_order')}
                </h2>
                </React.Fragment>
                }
            </React.Fragment>
            }

            <div className="p-hr p-margin-bottom-10" />
            
            <div className="p-grid">
                <div className="p-col-1">#</div>
                <div className="p-col-6 p-w-bold">{trans('res.mgt.food')}</div>
                <div className="p-col-2 p-w-bold p-r">{trans('res.mgt.qty')}</div>
                {!isNewOrder &&
                <div className="p-col-3 p-w-bold p-r">{trans('res.mgt.total')}</div>
                }
            </div>

            <div className="p-hr p-padding-top-0 p-margin-bottom-10" />

            {(()=>{
                let num = 0;

                return (
                    <React.Fragment>
                    {orderData.map((v) => {
                        return (
                            <div className="order-item-view">
                                <h3 className="cate-title">{v.cateNames?v.cateNames[selectedLocale]?v.cateNames[selectedLocale]:v.cateNames[defaultLocale]:trans('res.mgt.cate_other')}</h3>
        
                                {v.products.map(p => {
                                    num += 1;

                                    return (
                                        <React.Fragment>
                                        {(()=>{
                                            if(p.items.length===1){
                                                const item = p.items[0];
        
                                                return (
                                                    <div className="p-grid p-vertical-c">
                                                        <div className="p-col-1">#{num}</div>
                                                        <div className="p-col-6">
                                                            <div>{Object.keys(p.names).length>0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}</div>
                                                            {item.itemOptionValueTrans.length>0 && 
                                                            <div className="p-size-11 p-margin-top-5">
                                                                - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale]?opt.names[selectedLocale]:opt.names[defaultLocale])).join(', ')}
                                                            </div>
                                                            }
                                                            {item.note && <div className="p-size-11 p-margin-top-5">- {item.note}</div>}
                                                        </div>
                                                        <div className="p-col-2 p-r"><span>{item.quantity}</span></div>
                                                        {!isNewOrder && <div className="p-col-3 p-r">{moneyFormat(item.total, currency)}</div>}
                                                    </div>
                                                )
                                            }else{
                                                return (
                                                    <React.Fragment>
                                                    <div className="p-grid">
                                                        <div className="p-col-2"></div>
                                                        <div className="p-col-10 p-margin-bottom-5">
                                                            {Object.keys(p.names).length>0 ? p.names[selectedLocale] ? p.names[selectedLocale] : p.names[defaultLocale] ? p.names[defaultLocale] : p.name : p.name}
                                                        </div>
                                                    </div>

                                                    {p.items.map((item, itemIdx) => {
                                                        return (
                                                            <div className="p-grid p-vertical-c">
                                                                <div className="p-col-1">#{num}</div>
                                                                <div className="p-col-6" style={{borderBottom: itemIdx<p.items.length-1?'1px solid #d8d8d8':''}}>
                                                                {item.itemOptionValueTrans.length===0 && !item.note?
                                                                    <div className="p-size-11">{trans('res.mgt.food_select_standard')}</div>
                                                                :
                                                                <React.Fragment>
                                                                    {item.itemOptionValueTrans.length>0 && 
                                                                    <div className="p-size-11 p-margin-top-5">
                                                                        - {item.itemOptionValueTrans.map(opt => (opt.names[selectedLocale]?opt.names[selectedLocale]:opt.names[defaultLocale])).join(', ')}
                                                                    </div>
                                                                    }
                                                                    {item.note && <div className={"p-size-11 " + (item.productVariantName || item.productVariantNames?"p-margin-top-5":"")}>- {item.note}</div>}
                                                                </React.Fragment>
                                                                }
                                                                </div>
                                                                <div className="p-col-2 p-r"><span>{item.quantity}</span></div>
                                                                {!isNewOrder && <div className="p-col-3 p-r">{moneyFormat(item.total, currency)}</div>}
                                                            </div>
                                                        )
                                                    })}
                                                    </React.Fragment>
                                                )
                                            }
                                        })()}
                                        </React.Fragment>
                                    )
                                })}

                                {v.adjustItems && v.adjustItems.length>0 && v.adjustItems.map(item => {
                                    num += 1;

                                    return (
                                        <div className="p-grid p-vertical-c">
                                            <div className="p-col-1">#{num}</div>
                                            <div className="p-col-6">
                                                <div>{item.productName}</div>
                                            </div>
                                            <div className="p-col-2 p-r"><span>{item.quantity}</span></div>
                                            {!isNewOrder && <div className="p-col-3 p-r">{moneyFormat(item.total, currency)}</div>}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    <div className={"p-hr p-padding-top-0" + (!isNewOrder?' p-margin-bottom-15':'')}></div>
                    </React.Fragment>
                )
            })()}
        </React.Fragment>
    )
}

export function translatePaymentState(trans, state){
    switch (state) {
        case PAYMENT_STATE.awaiting:
            return trans('res.mgt.awaiting_payment');
        case PAYMENT_STATE.completed:
            return trans('res.mgt.completed');
        default:
            return "";
    }
}

export function translatePaymentMethod(trans, method){
    switch (method) {
        case PAYMENT_METHOD.Cash:
            return trans('res.mgt.cash');
        case PAYMENT_METHOD.SwipeCard:
            return trans('res.mgt.swipe_card');
        case PAYMENT_METHOD.CreditCard:
            return trans('res.mgt.credit_card');
        default:
            return "";
    }
}

export function translateShippingState(trans, state){
    switch (state) {
        case SHIPPING_STATE.pending:
            return trans('res.mgt.delivery_pending');
        case SHIPPING_STATE.preparing:
            return trans('res.mgt.delivery_preparing');
        case SHIPPING_STATE.ready:
            return trans('res.mgt.delivery_ready');
        case SHIPPING_STATE.completed:
            return trans('res.mgt.completed');
        default:
            return "";
    }
}